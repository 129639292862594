@charset "UTF-8";

// メインレイアウト
//
// 1:1カラム / 2:2カラム

$main-layout: 1 !default;

// 2カラム時にサイドカラムをどちらに配置するか。使用する方に ture。
$side-right: true !default;
$side-left:  false !default;


// Breakpoints
//
// 表示していないが単位は px

$grid-breakpoints: (
  'md': 768,
  'lg': 768,
) !default;


// メインコンテンツの横幅
//
// 表示していないが単位は px

$width-main: 1040 !default;


// サイドカラムの横幅。
//
// 単位は px

$width-right-column: 280 !default;
$width-left-column:  280 !default;


// メインカラムとサイドカラムの間隔
//
// 単位はpx

$main-side-margin: 44; // PC時
$main-top-margin:  44; // タブレット&スマートフォン時


// container の左右の padding
//
// 単位はpx

$container-padding-lg: 20 !default; // 2カラム時の横幅の計算のため、'lg' のみ変数

$container-padding: (
  'sm': 16,
  'md': 20,
  'lg': $container-padding-lg,
  ) !default;


// メインコンテンツの横幅
//
// レイアウトカラム数に応じて横幅が自動で決まる

@if $main-layout == 3 {
    $width-main: $width-main + $main-side-margin + $width-right-column + $width-left-column + $container-padding-lg * 2;
  } @else if $main-layout == 2 and $side-right == true and $side-left == false {
      $width-main: $width-main + $main-side-margin + $width-right-column + $container-padding-lg * 2;
  } @else if $main-layout == 2 and $side-left == true and $side-right == false {
     $width-main: $width-main + $main-side-margin + $width-left-column + $container-padding-lg * 2;
  } @else {
    $width-main: $width-main;
  }


// Container Width
//
// .container の最大値。メインコンテンツの横幅になる。単位はpx。

$container-width: (
  'md': 768,
  'lg': $width-main,
  ) !default;


// ベースのカラム数
//
// デザイン用コンテンツのカラム数。

$grid-columns: 12 !default;


// ベースの文字サイズ
//
// 入力単位は px、出力単位は rem。

$base-font-size: (
  'sm': 14,
  'md': 14,
  'lg': 16,
  ) !default;


// 段落の余白
// 単位は em。
//

$paragraph-margin: (
  'sm': 1,
  'md': 1,
  'lg': 1,
  ) !default;


// <section> 同士の余白
//
//

$section-margin: 4em;


// headerの高さ
//
//

$height-header-sm: 7.4rem;
$height-header-md: 5rem;
$height-header-lg: 12rem;


// z-index
//
//

$z-index-header:   4 !default;
$z-index-contents: 0 !default;
$z-index-footer:   1 !default;


// Typography
//
// Font family
//
//

$font-family: "YakuHanJPs", -apple-system, BlinkMacSystemFont, "Helvetica Neue","Segoe UI","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,arial,sans-serif;
//$font-family: "YakuHanJPs", -apple-system, BlinkMacSystemFont, Helvetica, Arial, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
//$font-family: "YakuHanJPs", Helvetica, Arial, "游ゴシック体", "Yu Gothic", YuGothic,  "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
//$font-family: "YakuHanJPs", Arial, "メイリオ", Meiryo, sans-serif;


// line-height
//
//

$base-line-height: (
  'sm': 1.8,
  'md': 1.8,
  'lg': 1.8,
  ) !default;


// 見出し用フォントサイズ
//
//

$h1-font-size: 2.0em;
$h2-font-size: 1.8em;
$h3-font-size: 1.6em;
$h4-font-size: 1.4em;
$h5-font-size: 1.2em;
$h6-font-size: 1.0em;
$h-scale-medium: 1.15;    // medium用
$h-scale-large:  1.1875;  // large用


// Fontawesome
//
//
$icon-arrows:    '\f138'; // 丸あり f138 丸なし  f054
$icon-pdf:       '\f1c1'; // PDF
$icon-window:    '\f2d2'; // 別ウインドウ
$icon-telephone: '\f098'; // 電話
$icon-mail:      '\f003'; // メール
$icon-fax:       '\f1ac'; // FAX
$icon-privacy:   '\f13e'; // プライバシーポリシー
$icon-link:      '\f0c1'; // リンク
$icon-company:   '\f1ad'; // 企業情報
$icon-policy:    '\f132'; // 勧誘方針
$icon-access:    '\f1b9'; // 交通アクセス
$icon-recruit:   '\f2b5'; // 採用？
$icon-circle:    '\f10c'; // まる
$icon-close:     '\f00d'; // ばつ


// Color
//
//

$body-color:    #555 !default; // デフォルトテキストカラー
$body-bg:       #ffffff !default; // デフォルトバックグラウンドカラー
$header-color:  transparent !default; // header background color
$footer-color:  #ffebeb !default; // footer background color

$base-color:    #1585f2 !default;
$first-color:   #E97607 !default;
$second-color:  #41B034 !default;
$third-color:   #EB6EA6 !default;
$fourth-color:  #e9546b !default;
$five-color:    #595959 !default;

$gray-dark:     #333333 !default;
$gray:          #55595c !default;
$gray-light:    #818a91 !default;
$gray-lighter:  #dfe2e4 !default;
$gray-lightest: #eceeef !default;

$color-white:  #fff    !default;
$color-black:  #000    !default;
$color-red:    #cc0033 !default;
$color-yellow: #fff000 !default; // Y100
$color-orange: #f18f2c !default;
$color-green:  #63a764 !default;

$link-color:                 #0033cc !default;
$link-decoration:            none !default;
$link-hover-color:           $base-color !default;
$link-hover-decoration:      underline !default;

// Table
//
//

$table-border-color: #ccc !default; // Tableのボーダーの色
$table-th-bg-color:  #fbfbf6 !default; // th のバックグラウンドカラー
$table-td-bg-color:  #fff !default;   // td のバックグラウンドカラー
